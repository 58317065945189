<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="6">
        <div>
          <div class="d-inline-flex align-center">
            <h3>위치 타겟 사용하기</h3>
            <v-switch
              class="ml-3 mt-3 mb-0"
              v-model="useLocationTarget"
              :disabled="isDialogTypeEdit"
              color="primary"
              inset
            />
          </div>
        </div>

        <template v-if="useLocationTarget">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{
              required: campaign.targetLocations.length > 0
            }"
          >
            <v-radio-group
              v-model="campaign.targetLocationsType"
              class="d-inline-block mt-0"
              :error-messages="errors"
              :disabled="isDialogTypeEdit"
              :hide-details="!errors.length"
            >
              <div
                v-for="(targetLocationsType, index) in TargetLocationsTypeList"
                :key="index"
                class="mb-5"
              >
                <v-radio :value="targetLocationsType.value" class="ma-0">
                  <template v-slot:label>
                    <div>
                      <h4>
                        {{ targetLocationsType.text }}
                      </h4>
                      <h5>{{ targetLocationsType.comment }}</h5>
                    </div>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </ValidationProvider>

          <text-field-title
            title="위치"
            subTitle="특정 위치에 거주하거나 방문한 사람"
          />
          <v-autocomplete
            v-model="location"
            :items="locations"
            :loading="isLoading"
            :search-input.sync="searchString"
            item-text="place_name"
            hide-no-data
            item-value="xy"
            clearable
            prepend-inner-icon="mdi-magnify"
            outlined
            return-object
            auto-select-first
            @keyup.enter="getLocations()"
            hide-details
          >
            <template v-slot:selection="data">
              <v-chip
                :key="JSON.stringify(data.item.place_name)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                label
                @click:close="data.parent.selectItem(data.item.place_name)"
                color="info"
                class="px-1 py-5"
                style="height: 60px"
              >
                <h3 class="px-3">{{ data.item.place_name }}</h3>
              </v-chip>
            </template>
          </v-autocomplete>

          <text-field-title title="반경" class="mt-3" />
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{
              required: true,
              min_value: 1
            }"
          >
            <v-text-field
              v-model="radius"
              persistent-hint
              height="48"
              outlined
              dense
              :disabled="isDialogTypeEdit"
              :error-messages="errors"
              background-color="white"
              class="input_add custom-margin"
              hide-details
            >
              <template v-slot:append>
                <v-col class="pt-2" align="center"> KM </v-col>
              </template>
            </v-text-field>
            <h4 class="red--text mt-2 font size12">
              {{ errors.length > 0 ? errors[0] : '' }}
            </h4>
          </ValidationProvider>

          <!-- <h3 v-if="circles[0]">
          반경: {{ Math.round(circles[0].circle.getRadius()) }}m
        </h3> -->
          <!-- <div class="d-flex align-center">
          <h3>
            선택된 위치 목록
          </h3>
          <v-tooltip bottom v-if="campaign.rs3.locations.length > 0">
            <template #activator="{ on: onTooltip }">
              <v-btn
                v-on="{ ...onTooltip }"
                icon
                @click="campaign.rs3.locations = circles = []"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>모두 지우기</span>
          </v-tooltip>
        </div> -->
          <ValidationProvider
            :rules="{
              required: !campaign.targetLocations.length
            }"
          >
            <v-input type="hidden" v-model="campaign.targetLocations" />
            <v-row
              v-if="!campaign.targetLocations.length"
              class="text-subtitle-1 red--text ma-0 mt-3"
              align="center"
              justify="center"
              style="height: 20px"
            >
              <v-icon color="error"> mdi-alert </v-icon>
              <v-col cols="12" class="text-center">
                지도에서 위치를 선택해 주세요.
              </v-col>
            </v-row>
          </ValidationProvider>
          <v-hover
            v-for="(location, index) in campaign.targetLocations"
            :key="index"
          >
            <template v-slot:default="{ hover }">
              <v-card dark class="pa-3 ma-1">
                <p class="title">주소: {{ location.address }}</p>
                <p class="title">
                  위도: {{ location.lat.toString().slice(0, 14) }}
                </p>
                <p class="title">
                  경도: {{ location.lng.toString().slice(0, 14) }}
                </p>
                <p class="title">
                  반경: {{ Number(location.radius).toLocaleString() }}m
                </p>
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute opacity="0.9">
                    <v-tooltip bottom>
                      <template #activator="{ on: onTooltip }">
                        <v-btn
                          v-on="{ ...onTooltip }"
                          icon
                          @click="
                            () => {
                              setCenter(location.lat, location.lng);
                              zoomIn(8);
                            }
                          "
                          ><v-icon>mdi-crosshairs-gps</v-icon></v-btn
                        >
                      </template>
                      <span>위치이동</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="!isDialogTypeEdit">
                      <template #activator="{ on: onTooltip }">
                        <v-btn
                          v-on="{ ...onTooltip }"
                          icon
                          @click="
                            () => {
                              campaign.targetLocations.splice(index, 1);
                              setMarkers(null);
                              removeCircles(index);
                            }
                          "
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                      </template>
                      <span>삭제</span>
                    </v-tooltip>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </template>
      </v-col>
      <v-col cols="12" lg="6" v-if="useLocationTarget">
        <div id="kakaoMap" style="width: 100%; height: 600px"></div>
        <h3 class="mt-5">반경을 입력 또는 선택해주세요.</h3>
        <v-slider
          v-model="radiusSlider"
          :tick-labels="ticksLabels"
          :min="1"
          :max="10"
          step="1"
          class="mt-3"
          color="primary"
          ticks="always"
          tick-size="4"
          track-color="grey"
          :disabled="isDialogTypeEdit"
          @input="handleRadiusChange"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import TextFieldTitle from '@/components/TextFieldTitle.vue';
import { TargetLocationsTypeList } from '@/config/admin/campaign';

export default {
  components: {
    TextFieldTitle,
    ValidationProvider
  },
  data() {
    return {
      map: null,
      infowindow: null,
      location: null,
      locations: [],
      isLoading: false,
      searchString: null,
      circles: [], // 클릭으로 그려진 원과 반경 정보를 표시하는 선과 커스텀오버레이를 가지고 있을 배열입니다
      markers: [], /// 지도에 표시된 마커 객체를 가지고 있을 배열입니다
      TargetLocationsTypeList: TargetLocationsTypeList,
      useLocationTarget: false,
      radius: 3,
      radiusSlider: 3
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      isDialogTypeEdit: 'campaign/isDialogTypeEdit'
    }),
    radiusMeter() {
      return this.radius * 1000;
    },
    ticksLabels() {
      return Array.from(Array(10).keys(), n => {
        return [1, 3, 5, 7, 9].includes(n + 1) ? `${n + 1}km` : '';
      });
    }
  },
  watch: {
    location(obj) {
      if (obj) {
        // console.log(obj);
        this.campaign.targetLocations.x = obj.x;
        this.campaign.targetLocations.y = obj.y;
        this.setCenter(obj.y, obj.x);
        this.zoomIn(3);
      }
    },
    useLocationTarget(newValue) {
      if (newValue) {
        // 등록된 캠페인은 init 불필요
        if (!this.campaign.id) {
          this.$nextTick(function () {
            this.init();
          });
        }
      } else {
        this.campaign.targetLocations = [];
        this.campaign.targetLocationsType = 'R'; // 실시간
        this.location = null;
      }
    },
    radius() {
      if (this.radiusSlider === this.radius) return;
      this.radiusSlider =
        this.radius >= 10 ? 10 : this.radius <= 1 ? 1 : this.radius;
      let index = null;

      if (this.campaign.targetLocations) {
        index = this.campaign.targetLocations.length > 0 ? 0 : null;
        this.campaign.targetLocations.forEach(location => {
          location.radius = this.radiusMeter;
        });
      }

      this.removeCircles(index);
      this.setCirclesOnMap();
    }
  },
  mounted() {
    this.useLocationTarget = this.campaign.targetLocations.length > 0;

    if (!this.campaign.targetLocationsType)
      this.campaign.targetLocationsType =
        this.campaign.targetLocations.length > 0 ? 'D' : 'R';

    this.$nextTick(function () {
      this.init();
    });
  },
  methods: {
    init() {
      const mapContainer = document.getElementById('kakaoMap'); // 지도를 표시할 div

      if (!mapContainer) return;
      this.mapInit();
      this.setCirclesOnMap();
      this.setMarkersOnMap();

      // 위치타겟 사용 하는 경우
      if (this.useLocationTarget) {
        let radius = this.campaign.targetLocations[0]?.radius;
        this.radius = radius ? radius / 1000 : 3;
      }
    },
    mapInit(lat = 37.488111, lng = 127.011533) {
      if (this.$kakao && this.$kakao.maps) {
        const kakao = this.$kakao;

        //주소정보요청을 위한 geocoder 서비스 생성

        // 마커를 클릭하면 장소명을 표출할 인포윈도우 입니다
        this.infowindow = new kakao.maps.InfoWindow({
          zIndex: 1
        });
        const mapContainer = document.getElementById('kakaoMap'), // 지도를 표시할 div
          mapOption = {
            center: new kakao.maps.LatLng(lat, lng), // 지도의 중심좌표
            level: 8, // 지도의 확대 레벨
            disableDoubleClickZoom: true
          };

        const map = (this.map = new kakao.maps.Map(mapContainer, mapOption)); // 지도를 생성합니다

        // 줌 인터페이스 추가
        const zoomControl = new kakao.maps.ZoomControl();
        // 지도 오른쪽에 줌 컨트롤이 표시되도록 지도에 컨트롤을 추가한다.
        map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
        let drawingFlag = false; // 원이 그려지고 있는 상태를 가지고 있을 변수입니다
        let centerPosition = null; // 원의 중심좌표 입니다
        let drawingCircle = null; // 그려지고 있는 원을 표시할 원 객체입니다
        let drawingLine = null; // 그려지고 있는 원의 반지름을 표시할 선 객체입니다
        let drawingOverlay = null; // 그려지고 있는 원의 반경을 표시할 커스텀오버레이 입니다
        // let isDrawing = false;
        // let drawingDot = null; // 그려지고 있는 원의 중심점을 표시할 커스텀오버레이 입니다

        if (!this.isDialogTypeEdit) {
          // 지도에 클릭 이벤트를 등록합니다
          kakao.maps.event.addListener(
            map,
            'click',
            async function (mouseEvent) {
              this.removeCircles();
              this.setMarkers(null);

              // 원이 그려질 중심좌표를 클릭한 위치로 설정합니다
              centerPosition = mouseEvent.latLng;

              this.clickEvent(centerPosition);
            }.bind(this)
          );
        }

        // 지도에 마우스무브 이벤트를 등록합니다
        // 원을 그리고있는 상태에서 마우스무브 이벤트가 발생하면 그려질 원의 위치와 반경정보를 동적으로 보여주도록 합니다
        kakao.maps.event.addListener(map, 'mousemove', function (mouseEvent) {
          // 마우스무브 이벤트가 발생했을 때 원을 그리고있는 상태이면
          if (drawingFlag) {
            // 마우스 커서의 현재 위치를 얻어옵니다
            const mousePosition = mouseEvent.latLng;

            // 그려지고 있는 선을 표시할 좌표 배열입니다. 클릭한 중심좌표와 마우스커서의 위치로 설정합니다
            const linePath = [centerPosition, mousePosition];

            // 그려지고 있는 선을 표시할 선 객체에 좌표 배열을 설정합니다
            drawingLine.setPath(linePath);

            // 원의 반지름을 선 객체를 이용해서 얻어옵니다
            const length = drawingLine.getLength();

            if (length > 0) {
              // 그려지고 있는 원의 중심좌표와 반지름입니다
              const circleOptions = {
                center: centerPosition,
                radius: length
              };

              // 그려지고 있는 원의 옵션을 설정합니다
              drawingCircle.setOptions(circleOptions);

              // 반경 정보를 표시할 커스텀오버레이의 내용입니다
              const radius = Math.round(drawingCircle.getRadius()),
                content =
                  '<div class="secondary" style="padding:10px; color:white">반경 <span class="number">' +
                  Number(radius).toLocaleString() +
                  '</span>m</div>';

              // 반경 정보를 표시할 커스텀 오버레이의 좌표를 마우스커서 위치로 설정합니다
              drawingOverlay.setPosition(mousePosition);

              // 반경 정보를 표시할 커스텀 오버레이의 표시할 내용을 설정합니다
              drawingOverlay.setContent(content);

              // 그려지고 있는 원을 지도에 표시합니다
              drawingCircle.setMap(map);

              // 그려지고 있는 선을 지도에 표시합니다
              drawingLine.setMap(map);

              // 그려지고 있는 원의 반경정보 커스텀 오버레이를 지도에 표시합니다
              drawingOverlay.setMap(map);
            } else {
              drawingCircle.setMap(null);
              drawingLine.setMap(null);
              drawingOverlay.setMap(null);
            }
          }
        });
      }
    },
    coord2Address(coord, centerPosition) {
      const kakao = this.$kakao;
      const geocoder = new kakao.maps.services.Geocoder();

      return new Promise(resolve => {
        geocoder.coord2Address(
          coord.getLng(),
          coord.getLat(),
          (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
              resolve({
                address: result[0].address.address_name,
                centerPosition
              });
            }
          }
        );
      });
    },
    // 마커를 생성하고 지도위에 표시하는 함수입니다
    addMarker(position) {
      const { $kakao, map, markers } = this;
      // 마커를 생성합니다
      const marker = new $kakao.maps.Marker({
        position: position
      });

      // 마커가 지도 위에 표시되도록 설정합니다
      marker.setMap(map);

      // 생성된 마커를 배열에 추가합니다
      markers.push(marker);
    },
    // 배열에 추가된 마커들을 지도에 표시하거나 삭제하는 함수입니다
    setMarkers(map) {
      const { markers } = this;
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
      }
    },
    setCirclesOnMap() {
      const { $kakao, map } = this;
      const locations = this.campaign.targetLocations;

      locations.forEach((location, index) => {
        const centerPosition = new $kakao.maps.LatLng(
          location.lat,
          location.lng
        );

        // 원 객체를 생성합니다
        const circle = new $kakao.maps.Circle({
          center: centerPosition, // 원의 중심좌표입니다
          radius: location.radius, // 원의 반지름입니다 m 단위 이며 선 객체를 이용해서 얻어옵니다
          strokeWeight: 1, // 선의 두께입니다
          strokeColor: '#00a0e9', // 선의 색깔입니다
          strokeOpacity: 0.1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid', // 선의 스타일입니다
          fillColor: '#00a0e9', // 채우기 색깔입니다
          fillOpacity: 0.2 // 채우기 불투명도입니다
        });

        circle.setMap(map);
        const radiusObj = {
          polyline: new $kakao.maps.Polyline(),
          circle: circle,
          overlay: new $kakao.maps.CustomOverlay()
        };

        this.circles.push(radiusObj);
        if (locations.length === index + 1) {
          this.setCenter(location.lat, location.lng);
        }
      });
    },
    setMarkersOnMap() {
      const { $kakao } = this;
      const { targetLocations } = this.campaign;
      targetLocations.forEach(location => {
        const centerPosition = new $kakao.maps.LatLng(
          location.lat,
          location.lng
        );
        this.addMarker(centerPosition);
      });
    },
    getLocations() {
      const kakao = this.$kakao;
      const searchString = this.searchString;
      const ps = new kakao.maps.services.Places();
      ps.keywordSearch(searchString, this.placesSearchCB);
    },
    placesSearchCB(data, status) {
      const kakao = this.$kakao;
      const map = this.map;

      if (status !== 'OK') {
        return false;
      }

      if (data.length > 0) {
        this.locations.push(
          ...data.map(data => {
            const temp = {
              ...data,
              xy: `${data.x},${data.y}`
            };
            return temp;
          })
        );
      }

      if (status === kakao.maps.services.Status.OK) {
        // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
        // LatLngBounds 객체에 좌표를 추가합니다
        const bounds = new kakao.maps.LatLngBounds();

        for (let i = 0; i < data.length; i++) {
          this.displayMarker(data[i]);
          bounds.extend(new kakao.maps.LatLng(data[i].y, data[i].x));
        }

        // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
        map.setBounds(bounds);
      }
    },
    // 지도에 표시되어 있는 모든 원과 반경정보를 표시하는 선, 커스텀 오버레이를 지도에서 제거합니다
    removeCircles(index = null) {
      if (index !== null) {
        this.circles[index].circle.setMap(null);
        // this.circles[index].polyline.setMap(null);
        this.circles[index].overlay.setMap(null);
        this.circles.splice(index, 1);
      } else {
        for (let i = 0; i < this.circles.length; i++) {
          this.circles[i].circle.setMap(null);
          // this.circles[i].polyline.setMap(null);
          this.circles[i].overlay.setMap(null);
        }
        this.circles = [];
        this.campaign.targetLocations = [];
      }
    },

    // 지도에서 클릭한 반경의 중심 위치를 가져옵니다.
    setLocation(position, radius, address) {
      // console.log(position, radius, address);
      const location = {
        lng: position.La,
        lat: position.Ma,
        radius: radius,
        address: address
      };
      this.campaign.targetLocations.push(location);
    },

    // 마우스 우클릭 하여 원 그리기가 종료됐을 때 호출하여
    // 그려진 원의 반경 정보와 반경에 대한 도보, 자전거 시간을 계산하여
    // HTML Content를 만들어 리턴하는 함수입니다
    getTimeHTML(radius) {
      // 도보의 시속은 평균 4km/h 이고 도보의 분속은 67m/min입니다
      let walkkTime = (radius / 67) | 0;
      let walkHour = '',
        walkMin = '';

      // 계산한 도보 시간이 60분 보다 크면 시간으로 표시합니다
      if (walkkTime > 60) {
        walkHour =
          '<span class="number">' + Math.floor(walkkTime / 60) + '</span>시간 ';
      }
      walkMin = '<span class="number">' + (walkkTime % 60) + '</span>분';

      // 자전거의 평균 시속은 16km/h 이고 이것을 기준으로 자전거의 분속은 267m/min입니다
      let bycicleTime = (radius / 227) | 0;
      let bycicleHour = '',
        bycicleMin = '';

      // 계산한 자전거 시간이 60분 보다 크면 시간으로 표출합니다
      if (bycicleTime > 60) {
        bycicleHour =
          '<span class="number">' +
          Math.floor(bycicleTime / 60) +
          '</span>시간 ';
      }
      bycicleMin = '<span class="number">' + (bycicleTime % 60) + '</span>분';

      // 거리와 도보 시간, 자전거 시간을 가지고 HTML Content를 만들어 리턴합니다
      let content = `<ul class="secondary " style="color:white;padding:10px">`;
      content += '    <li>';
      content +=
        '        <span class="label">총거리: </span><span class="number">' +
        Number(radius).toLocaleString() +
        '</span>m';
      content += '    </li>';
      content += '    <li>';
      content +=
        '        <span class="label">도보: </span>' + walkHour + walkMin;
      content += '    </li>';
      content += '    <li>';
      content +=
        '        <span class="label">자전거: </span>' +
        bycicleHour +
        bycicleMin;
      content += '    </li>';
      content += '</ul>';
      return content;
    },
    // 지도에 마커를 표시하는 함수입니다
    displayMarker(place) {
      const kakao = this.$kakao;
      const map = this.map;
      const infowindow = this.infowindow;
      // 마커를 생성하고 지도에 표시합니다
      const marker = new kakao.maps.Marker({
        map: map,
        position: new kakao.maps.LatLng(place.y, place.x)
      });

      // 마커에 클릭이벤트를 등록합니다
      kakao.maps.event.addListener(marker, 'click', function () {
        // 마커를 클릭하면 장소명이 인포윈도우에 표출됩니다
        infowindow.setContent(
          '<div style="padding:5px;font-size:12px;">' +
            place.place_name +
            '</div>'
        );
        infowindow.open(map, marker);
      });
    },
    zoomIn(inLevel = null) {
      // 현재 지도의 레벨을 얻어옵니다
      const map = this.map;
      const level = map.getLevel();

      // 지도를 1레벨 내립니다 (지도가 확대됩니다)
      if (inLevel) {
        map.setLevel(inLevel);
      } else {
        map.setLevel(level - 1);
      }

      // 지도 레벨을 표시합니다
      // displayLevel();
    },
    zoomOut(outLevel = null) {
      // 현재 지도의 레벨을 얻어옵니다
      const map = this.map;
      const level = map.getLevel();

      // 지도를 1레벨 올립니다 (지도가 축소됩니다)
      if (outLevel) {
        map.setLevel(outLevel);
      } else {
        map.setLevel(level + 1);
      }

      // 지도 레벨을 표시합니다
      // displayLevel();
    },
    setCenter(lat, lng) {
      const kakao = this.$kakao;
      const map = this.map;
      // 이동할 위도 경도 위치를 생성합니다
      const moveLatLon = new kakao.maps.LatLng(lat, lng);

      // 지도 중심을 이동 시킵니다
      map.setCenter(moveLatLon);
    },
    panTo(lat, lng) {
      const kakao = this.$kakao;
      const map = this.map;
      // 이동할 위도 경도 위치를 생성합니다
      const moveLatLon = new kakao.maps.LatLng(lat, lng);

      // 지도 중심을 부드럽게 이동시킵니다
      // 만약 이동할 거리가 지도 화면보다 크면 부드러운 효과 없이 이동합니다
      map.panTo(moveLatLon);
    },
    colorCodeGenerator() {
      return '#' + ((Math.random() * 0xffffff) << 0).toString(16);
    },
    // 위치 반경 변경시
    handleRadiusChange() {
      this.radius = this.radiusSlider;
      let index = null;
      if (this.campaign.targetLocations) {
        index = this.campaign.targetLocations.length > 0 ? 0 : null;
        this.campaign.targetLocations.forEach(location => {
          location.radius = this.radiusMeter;
        });
      }

      this.removeCircles(index); // 그려진 원이 있으면 지우고
      this.setCirclesOnMap(); // 새로 원 그리기
    },
    clickEvent(centerPosition) {
      const kakao = this.$kakao;

      // 원 객체를 생성합니다
      const circle = new kakao.maps.Circle({
        center: centerPosition, // 원의 중심좌표입니다
        radius: this.radiusMeter, // 원의 반지름입니다 m 단위 이며 선 객체를 이용해서 얻어옵니다
        strokeWeight: 1, // 선의 두께입니다
        strokeColor: '#00a0e9', // 선의 색깔입니다
        strokeOpacity: 0.1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다
        fillColor: '#00a0e9', // 채우기 색깔입니다
        fillOpacity: 0.2 // 채우기 불투명도입니다
      });

      const radius = Math.round(circle.getRadius()), // 원의 반경 정보를 얻어옵니다
        content = this.getTimeHTML(radius); // 커스텀 오버레이에 표시할 반경 정보입니다

      const eventPosition = new kakao.maps.LatLng(
        centerPosition.Ma,
        centerPosition.La
      );

      this.coord2Address(eventPosition, centerPosition).then(result => {
        const { address, centerPosition } = result;
        this.setLocation(centerPosition, radius, address);
      });

      // 반경정보를 표시할 커스텀 오버레이를 생성합니다
      const radiusOverlay = new kakao.maps.CustomOverlay({
        content: content, // 표시할 내용입니다
        xAnchor: 0,
        yAnchor: 0,
        zIndex: 1
      });
      // 마커를 생성합니다
      this.addMarker(eventPosition);

      // 원을 지도에 표시합니다
      circle.setMap(this.map);

      // 반경 정보 커스텀 오버레이를 지도에 표시합니다
      // radiusOverlay.setMap(map);

      // 배열에 담을 객체입니다. 원, 선, 커스텀오버레이 객체를 가지고 있습니다
      const radiusObj = {
        circle: circle,
        overlay: radiusOverlay
      };

      // 배열에 추가합니다
      // 이 배열을 이용해서 "모두 지우기" 버튼을 클릭했을 때 지도에 그려진 원, 선, 커스텀오버레이들을 지웁니다
      this.circles.push(radiusObj);
    }
  }
};
</script>
